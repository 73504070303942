import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Header from 'components/Header'
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = ({heading, subheading, callToAction, ctaAnchor}) => {
  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme='light'>
          <h1>{heading}</h1>
          <h4>{subheading}</h4>
          <Button as={AnchorLink} href={ctaAnchor}>
            {callToAction}
          </Button>
        </Details>
        <Thumbnail>
          <img src={dev} alt={subheading}/>
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};

export default Intro;
