import React from 'react'
import { Container, Card, TitleWrap } from 'components/common'
import { Wrapper, Item, Languages, ItemText, ItemContainer } from './styles';
import PreviewCompatibleImage from 'components/PreviewCompatibleImage'
import RenderedMarkdown from 'components/RenderedMarkdown'

export const Timeline = ({items}) => {
  return (
    <Wrapper as={Container}>
      {items.map((item) => (
        <Item key={item.title}>
          <ItemContainer>
            <a href={item.href}><PreviewCompatibleImage imageInfo={item} /></a>
            <ItemText>
              <RenderedMarkdown rawMarkdown={item.text}/>
              <TitleWrap as="a" href={item.href}>
                <h4>{item.title}</h4>
                <Languages>{item.technologies}</Languages>
              </TitleWrap>
            </ItemText>
          </ItemContainer>
        </Item>
      ))}
    </Wrapper>
  );
};

export default Timeline;
