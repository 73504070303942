import React from 'react'
import Timeline from 'components/Timeline'
import RenderedMarkdown from './RenderedMarkdown'
import { Container } from 'components/common';

const generateIdFromText = (text) => {
  const s = text.toLowerCase()
    .replace(/[^-\w\s]/g, '')
    .replace(/^\s+|\s+$/g, '') // trim leading/trailing spaces
    .replace(/[-\s]+/g, '-') // convert spaces to hyphens
    .replace(/-+$/g, '') // trim any trailing hyphens

  return s;
}

const ProjectList = ({ sections }) => (
  <>
    {sections.map((section) => (
      <>
        <Container id={generateIdFromText(section.heading)}>
          <h2 className="title">{section.heading}</h2>
          <div className="tile"><RenderedMarkdown rawMarkdown={section.description}/></div>
        </Container>
        <Timeline items={section.items || []} />
      </>
    ))}
  </>
)

//ProjectList.propTypes = {
//  gridItems: PropTypes.arrayOf(
//    PropTypes.shape({
//      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//      text: PropTypes.string,
//    })
//  ),
//}

export default ProjectList
