import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Intro from 'components/Intro'
import { Container } from 'components/common';
import Layout from '../components/Layout'
import ProjectList from '../components/ProjectList'
import RenderedMarkdown from '../components/RenderedMarkdown'

export const IndexPageTemplate = ({
  image,
  name,
  heading,
  subheading,
  callToAction,
  ctaAnchor,
  mainpitch,
  description,
  sections,
}) => (
  <>
    <Intro {...{heading,subheading,callToAction,ctaAnchor}} />
    <Container>
      <h1 id='about'>{mainpitch.title}</h1>
      <h3 className="subtitle"><RenderedMarkdown rawMarkdown={mainpitch.description}/></h3>
      <RenderedMarkdown rawMarkdown={description}/>
    </Container>
    <ProjectList sections={sections} />
    <Container id='contact'>
      <h2>Contact</h2>
      Looking to hire a Ruby Developer? Reach out via <h3 style={{display: 'inline'}}>contact✉jamedjo.co.uk</h3>
    </Container>
  </>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  callToAction: PropTypes.string,
  ctaAnchor: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  sections: PropTypes.arrayOf({
    heading: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        name={frontmatter.name}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        callToAction={frontmatter.callToAction}
        ctaAnchor={frontmatter.ctaAnchor}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        sections={frontmatter.sections}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        name
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        callToAction
        ctaAnchor
        mainpitch {
          title
          description
        }
        description
        sections {
          heading
          description
          items {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            technologies
            href
          }
        }
      }
    }
  }
`
