import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => {
  return (
    <Wrapper desktop={desktop} theme='light'>
      <AnchorLink href="#about">About</AnchorLink>
      <AnchorLink href="#open-source-projects">Open Source</AnchorLink>
      <AnchorLink href="#startups-and-projects">Startups</AnchorLink>
      <AnchorLink href="#childhood-projects">Origins</AnchorLink>
    </Wrapper>
  )

};

export default NavbarLinks;
