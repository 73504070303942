import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
  max-width: 1030px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  html & h4 {
    color: ${({ theme }) => (theme !== 'dark' ? '#212121' : '#fff')};
    margin-bottom: 0;
  }

  p {
    color: ${({ theme }) => (theme !== 'dark' ? '#707070' : '#c7c7c7')};
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  flex: 1;
`;

export const Languages = styled.div`
  opacity: 0.6;
  font-size: 14px;
  color: ${({ theme }) => (theme !== 'dark' ? '#000' : '#fff')};
`;

